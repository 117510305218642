
  import { Component, Vue } from 'vue-property-decorator';
  import BookmakerPromotionEdit from '@/components/BookmakerPromotionEdit.vue';
  import {
    FETCH_BOOKMAKER_PROMOTIONS_ITEM,
    UPDATE_BOOKMAKER_PROMOTION,
    DELETE_BOOKMAKER_PROMOTION
  } from '@/store/bookmakerPromotions';

  @Component({
    components: { BookmakerPromotionEdit }
  })
  export default class BookmakerPromotionDetails extends Vue {
    beforeMount() {
      this.$store.dispatch(FETCH_BOOKMAKER_PROMOTIONS_ITEM, this.$route.params.id);
    }

    public save() {
      (this.$refs.bookmakerPromotionEdit as any).$v.$touch();

      if ((this.$refs.bookmakerPromotionEdit as any).$v.$error) {
        return;
      }

      this.$store.dispatch(UPDATE_BOOKMAKER_PROMOTION)
        .then(() => this.$router.push({ name: 'app.bookmakerPromotions.list' }));
    }

    public remove() {
      this.$store.dispatch(DELETE_BOOKMAKER_PROMOTION)
        .then(() => this.$router.push({ name: 'app.bookmakerPromotions.list' }));
    }
  }
